<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-ground-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/fo/bg-2.jpg')})`
          }"
        >
          <span v-html="$t('public.crew_header')"></span>
        </div>
      </div>
    </section>

    <section class="section section-inner">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-crew.png" alt="" />
          </div>
          <div class="right-col">
            <h1>{{ $t("public.crew_text_1") }}</h1>

            <p>
              {{ $t("public.crew_text_2") }}
            </p>

            <ol>
              <li>
                <h3>{{ $t("public.crew_text_3") }}</h3>
                <p>
                  {{ $t("public.crew_text_4") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                  {{ $t("public.crew_text_5") }}
                </p>
                <img src="@/assets/img/crew-img-1.png" class="screen" alt="" />
              </li>
              <li>
                <h3>
                  {{ $t("public.crew_text_6") }}
                </h3>
                <img
                  src="@/assets/img/crew-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import cover from "@/mixins/cover";
import flight from "@/mixins/flight";

export default {
  name: "Crew",
  title() {
    return this.$t("pages.crew");
  },
  mixins: [cover, flight],
  computed: {
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightLoading: state => state.flightLoading,
      flightDetails: state => state.flightDetails
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapGetters("flight", {
      flight: "SORT_POSITION"
    })
  },

  methods: {
    ...mapActions("content", {
      actionItem: "item"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("content", {
      setEntityContent: "SET_ENTITY"
    }),
    ...mapActions("flight", {
      actionFlightCrewIndex: "flightCrew"
    })
  }
};
</script>

<style></style>
